import {gsap} from "gsap"

export default class SideMenu
{
    private readonly el: HTMLElement
    private readonly toggleBtn: HTMLElement
    private readonly box: HTMLElement
    private readonly backdrop: HTMLElement
    private readonly content: HTMLElement
    private readonly grids: HTMLElement[]
    private readonly tlClose: gsap.core.Timeline
    private readonly tlOpen: gsap.core.Timeline
    private opened: boolean

    constructor(rootElement: Element)
    {
        this.el = <HTMLElement>rootElement
        this.opened = false
        this.toggleBtn = <HTMLElement>this.el.querySelector('.msd-menu-toggle button')
        this.box = <HTMLElement>this.el.querySelector('.msd-menu-box')
        this.backdrop = <HTMLElement>this.el.querySelector('.msd-menu-backdrop')
        this.content = <HTMLElement>this.el.querySelector('.msd-menu-content')
        this.grids = <HTMLElement[]><unknown>this.el.querySelectorAll('.msd-menu-grid-col')

        this.tlClose = this.createCloseTimeline()
        this.tlOpen = this.createOpenTimeline()

        this.bind()
    }

    private bind(): void
    {
        this.toggleBtn.addEventListener("click", () => this.toggle())
        this.backdrop.addEventListener("click", () => this.hide())
        this.tlClose.eventCallback('onComplete', () => {
            this.box.style.display = 'none'
            this.toggleBtn.classList.remove('msd-menu-closed')
        })
        this.tlOpen.eventCallback('onComplete', () => {
            this.toggleBtn.classList.add('msd-menu-closed')
        })

        this.el.addEventListener('click', e => {
            const target = e.target as HTMLLinkElement
            const partialHref = target.getAttribute('href')
            if(
                target?.parentElement === null
                ||
                !target.parentElement.classList.contains('msd-menu-nav-item')
                ||
                partialHref === null
                ||
                (
                    !partialHref.startsWith('/#')
                    &&
                    !partialHref.startsWith('#')
                )
            ) {
                return
            }

            this.hide()
        })
    }

    public toggle(): void
    {
        this.opened ? this.hide() : this.show()
    }

    public show(): void
    {
        this.opened = true
        this.el.classList.add('-open')
        document.documentElement.classList.add('menu-open')
        this.box.style.display = 'block'
        this.tlClose.pause()
        this.tlOpen.play(0)
    }

    public hide(): void
    {
        this.opened = false
        this.el.classList.remove('-open')
        document.documentElement.classList.remove('menu-open')
        this.box.style.display = 'block'
        this.tlOpen.pause()
        this.tlClose.play(0)
    }

    private createCloseTimeline(): gsap.core.Timeline
    {
        const timeline = gsap.timeline({
            paused: true
        })

        const elList = [this.backdrop, this.content, ...this.grids]
        timeline.set(elList, {
            willChange: "transform,opacity"
        })

        timeline.fromTo(this.backdrop, {
            opacity: 1
        }, {
            opacity: 0,
            duration: .4
        }, 0)

        timeline.fromTo(this.grids, {
            opacity: 1
        }, {
            opacity: 0,
            duration: .2
        }, 0)

        timeline.fromTo(this.content, {
            x: "0%"
        }, {
            x: "100%",
            duration: .4
        }, 0)

        timeline.set(elList, {
            willChange: "auto"
        })

        return timeline
    }

    private createOpenTimeline(): gsap.core.Timeline
    {
        const timeline = gsap.timeline({
            paused: true
        })

        const elList = [this.backdrop, this.content, ...this.grids]
        timeline.set(elList, {
            willChange: "transform,opacity,background-color"
        })

        timeline.fromTo(this.backdrop, {
            opacity: 0
        }, {
            opacity: 1,
            duration: .5
        }, 0)

        timeline.fromTo(this.content, {
            backgroundColor: "#efefef",
            x: "100%"
        }, {
            backgroundColor: "#ffffff",
            x: "0%",
            ease: "expo.out",
            duration: 1
        }, 0)

        timeline.fromTo(this.grids, {
            x: "-35%"
        }, {
            x: "0%",
            ease: "expo.out",
            duration: 1
        }, 0)

        timeline.fromTo(this.grids, {
            opacity: 0
        }, {
            opacity: 1,
            duration: .5
        }, .1)

        timeline.set(elList, {
            willChange: "auto"
        })

        return timeline
    }
}
