<script lang="ts">
    import {FaqCategory, Faq} from "./store"
    import {slide} from "svelte/transition"
    import Translator from "bazinga-translator";

    export let categories: FaqCategory[]
    export let faqs: Faq[]
    export let locale: string
    
    let activeCategoryId: string|null = null
    let activeFaqId: string|null = null
    
    $: filteredFaqs = activeCategoryId === null ? faqs : faqs.filter(faq => faq.category === activeCategoryId)
    $: activeCategory = activeCategoryId === null ? null : categories.find(cat => cat.id === activeCategoryId)
</script>

<div class="flex flex-col">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-16">
        {#each categories as category, index (category.id)}
            <button class="flex flex-row justify-between items-center hover:underline decoration-east-bay hover:shadow-xl {index % 2 === 0 ? 'hover:rotate-[0.7deg]' : 'hover:-rotate-[0.7deg]'} hover:scale-[1.007] transition-all transform-gpu hover:-translate-y-1 rounded-md bg-white border-none p-7 shadow-md {activeCategoryId === category.id ? 'underline -translate-y-2' : ''}" on:click|preventDefault={() => activeCategoryId === category.id ? (activeCategoryId = null) : (activeCategoryId = category.id)}>
                <b class="text-2xl text-east-bay">{locale === "sk" ? category.nameSk : category.name}</b>
                <img src="/image/arrow-right.svg" alt="arrow right"/>
            </button>
        {/each}
    </div>
    
    <h2 class="text-center lg:text-left font-bold text-4xl mb-8">
        {Translator.trans("faq.title")}
        {#if activeCategory !== null}
             - {locale === "sk" ? activeCategory.nameSk : activeCategory.name}
        {/if}
    </h2>

    <div class="flex flex-col gap-6">
        {#each filteredFaqs as faq (faq.id)}
            <div  class="bg-white shadow-md flex flex-col rounded-md">
                <button on:click|preventDefault={() => activeFaqId === faq.id ? (activeFaqId = null) : (activeFaqId = faq.id)} class="flex hover:underline flex-row justify-between items-center text-left px-9 py-7">
                    <b class="text-2xl mr-9">{locale === "sk" ? faq.questionSk : faq.question}</b>
                    <img src="/image/arrow-right.svg" class="hidden lg:inline transition-all transform-gpu {activeFaqId === faq.id ? '-rotate-90' : 'rotate-90'}" alt="arrow toggle"/>
                </button>

                {#if activeFaqId === faq.id}
                    <div transition:slide|local class="pb-7 px-9">
                        {@html locale === "sk" ? faq.answerSk : faq.answer}
                    </div>
                {/if}
            </div>
        {/each}
    </div>
</div>